import api from './index';
import { trackPromise } from 'react-promise-tracker';
import { ReactTrackerAreas } from 'src/enums/reactTrackerAreas.enum';
import {
  InvoicesFilters,
  PostInvoice,
  InvoiceMetaData,
  invoice,
  ParamaseDiscoun,
  ParamaseCancelDiscoun,
  statuses,
  AddInvoice
} from 'src/types/invoice';

interface Data {
  newStatus: string;
}

interface ResponseGetInvoice {
  count?: number;
  data?: invoice[];
}

export const putInvoice = async (id: string, data: Data) => {
  return await trackPromise(
    api.put(`/invoice/${id}`, data),
    ReactTrackerAreas.UPDATE_INVOICE
  );
};

export const InvoiceAPI = {
  putInvoice: async (id: string, statuses: statuses): Promise<invoice> => {
    return await trackPromise(
      api.put(`/invoice/${id}`, { newStatus: statuses }),
      ReactTrackerAreas.UPDATE_INVOICE
    );
  },
  getInvoice: async (params: InvoicesFilters): Promise<ResponseGetInvoice> => {
    return await trackPromise(
      api.get(`/invoice`, { params }),
      ReactTrackerAreas.GET_INVOICE
    );
  },
  PostInvoiceMate: async (params: PostInvoice): Promise<InvoiceMetaData> => {
    return await trackPromise(
      api.post(`/invoice/meta`, params),
      ReactTrackerAreas.POST_INVOICE
    );
  },
  PostAddDiscount: async (params: ParamaseDiscoun): Promise<any> => {
    return await trackPromise(
      api.post(`/invoice/add-discount`, params),
      ReactTrackerAreas.POST_INVOICE
    );
  },
  PostCancelDiscount: async (params: ParamaseCancelDiscoun): Promise<any> => {
    return await trackPromise(
      api.post(`/invoice/cancel-discount`, params),
      ReactTrackerAreas.POST_INVOICE
    );
  },
  getInvoiceMail: async (id: string,): Promise<any> => {
    return await api.get(`/invoice/${id}/invoice-mail`)
  },
  CreateInvoice: async (params: AddInvoice): Promise<invoice> => {
    return await trackPromise(
      api.post(`/invoice/create`, params),
      ReactTrackerAreas.CREATE_INVOICE
    );
  },
  DeleteInvoice: async (invoiceId: string): Promise<void> => {
    return await trackPromise(
      api.delete(`/invoice/${invoiceId}`),
      ReactTrackerAreas.DELETE_INVOICE
    );
  },
};
