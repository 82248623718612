import type { FC, ReactNode } from 'react';
import React from 'react';
import { Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { UserRoleEnum } from 'src/enums/user';

interface HomeOwnerAuthGuardProps {
  children?: ReactNode;
}

const HomeOwnerAuthGuard: FC<HomeOwnerAuthGuardProps> = ({ children }) => {
  const { user: { role } } = useAuth();

  if (role !== UserRoleEnum.HOMEOWNER) {
    return <Redirect to="/404" />;
  }
  return (
    <>
      {children}
    </>
  );
};

HomeOwnerAuthGuard.propTypes = {
  children: PropTypes.node
};

export default HomeOwnerAuthGuard;
