import axios from 'axios';
import { API_BASE_URL } from 'src/constants';
import firebase from 'src/lib/firebase';

const instance = axios.create({
  baseURL: API_BASE_URL
});
instance.interceptors.request.use(
  async (config) => {
    const token = await firebase.auth().currentUser?.getIdToken();
    console.log(token)
    config.headers.Authorization = `bearer ${token}`;
    return config;
  },
  (error) => console.log(error)
);

instance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response && error.response.status === 401 && error.response.statusText === "Unauthorized") {
      firebase.auth().signOut()
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
);

export default instance;
