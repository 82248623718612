import type { FC } from 'react';
import React from 'react';
import {  useLocation } from 'react-router-dom';
import { useSelector } from '../store';
import useAuth from '../hooks/useAuth';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {
  const location = useLocation();
  console.table(location.pathname.includes("/app/appeals-details"))
  const { appeal } = useSelector((state) => state.appealDetails);
  const { user } = useAuth();
  const partnerLogo = (location.pathname.includes("/app/appeals-details") && appeal?.invitedBy?.logo !== undefined && location.pathname.includes(appeal._id) ) || (user?.role==="partner" && user?.logo)


  return (
    <div style={{ padding: '30px 0 20px' }}>
      <img
        width={"auto"}
        height="30px"
        alt="Logo"
        src={partnerLogo ?appeal?.invitedBy?.logo ||user?.logo  : "/static/logo.svg"}
        {...props}
      />
    </div>
  );
};

export default Logo;
