import type { User } from 'src/types/user';
import { trackPromise } from 'react-promise-tracker';
import { ReactTrackerAreas } from 'src/enums/reactTrackerAreas.enum';
import api from './index';

export const ReportAPI = {
  getReports: (params):Promise<{data:any,count:number}> =>
    trackPromise(
      api.get('/reports', { params: { ...params } }),
      ReactTrackerAreas.GET_REPORTS_LIST
    ),

  createReport: (body) =>
    trackPromise(
      api.post('/reports/properties-csv-report', body),
      ReactTrackerAreas.CREATE_NEW_REPORT
    )
};
