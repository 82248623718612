import type { FC } from 'react';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Client as ConversationsClient } from '@twilio/conversations';
import { TwilioConnectionStateEnum, TwilioConversationsEventTypeEnum } from 'src/enums/twilio.enum';
import { ConversationsAPI } from 'src/API/conversations';
import useAuth from 'src/hooks/useAuth';
import ReactNotifications from 'react-browser-notifications';

export interface TwilioContextValue {
  client: ConversationsClient | null;
  // conversationsList: any[];
}

const defaultTwilio = {
  client: null
  // conversationsList: []
};

const TwilioContext = createContext<TwilioContextValue>(defaultTwilio);

export const TwilioProvider: FC<any> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  const [currentClient, setCurrentClient] = useState(null);
  const [currentConversationsList, setCurrentConversationsList] = useState([]);
  const [notificationsRef, setNotificationsRef] = useState(null);
  const [message, setMessage] = useState<any>({});

  const handleConnectionStateChanged = (state): void => {
    switch (state) {
      case TwilioConnectionStateEnum.CONNECTING:
        console.table({ statusString: 'Connecting to Twilio…' });
        return;

      case TwilioConnectionStateEnum.CONNECTED:
        console.table({ statusString: 'You are connected.' });
        return;

      case TwilioConnectionStateEnum.DISCONNECTING:
        console.table({ statusString: 'Disconnecting from Twilio…' });
        return;

      case TwilioConnectionStateEnum.DISCONNECTED:
        console.table({ statusString: 'Disconnected.' });
        return;

      case TwilioConnectionStateEnum.DENIED:
        console.table({ statusString: 'Failed to connect.' });
        break;

      default:
        break;
    }
  };
  const handleConversationJoined = (conversation: any): void => {
    setCurrentConversationsList((lastValue) => [conversation, ...lastValue]);
  };
  const handleConversationLeft = (thisConversation): void => {
    const newConversationsList: any = currentConversationsList
      .filter((conversation: any) => conversation.sid !== thisConversation.sid);
    setCurrentConversationsList(newConversationsList);
  };
  const handleMessageAdded = (newMessage): void => {
    // TODO ADD NOTIFICATION WHEN NEW MESSAGE

    if (newMessage.author === user._id) return;
    if (window.location.href.includes(`appeals-details/${newMessage.attributes.conversations}`)) return;
    setMessage(newMessage);
    if (!window.location.href.includes(`appeals-details/${newMessage.attributes.conversations}`) && notificationsRef.supported()) notificationsRef.show();
  };
  const handleTokenAboutToExpire = async (): Promise<void> => {
    if (isAuthenticated) {
      const newTwilioToken = await ConversationsAPI.getTwilioConversationsToken();
      await currentClient.updateToken(newTwilioToken);
    }
  };

  const initClient = useCallback(async () => {
    if (currentClient === null && isAuthenticated) {
      const twilioToken = await ConversationsAPI.getTwilioConversationsToken();
      const newClient = await ConversationsClient.create(twilioToken);
      setCurrentClient(newClient);
    }
  }, [currentConversationsList, isAuthenticated, currentClient]);

  useEffect(() => {
    if (isAuthenticated && currentClient === null) {
      initClient();
    }
    if (currentClient !== null && !isAuthenticated) {
      currentClient.shutdown();
      currentClient.removeAllListeners();
      setCurrentClient(null);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (currentClient !== null) {
      currentClient.on(TwilioConversationsEventTypeEnum.CONNECTION_STATE_CHANGE, handleConnectionStateChanged);
      currentClient.on(TwilioConversationsEventTypeEnum.CONVERSATION_JOINED, handleConversationJoined);
      currentClient.on(TwilioConversationsEventTypeEnum.CONVERSATION_LEFT, handleConversationLeft);
      currentClient.on(TwilioConversationsEventTypeEnum.MESSAGE_ADDED, handleMessageAdded);
      currentClient.on(TwilioConversationsEventTypeEnum.TOKEN_ABOUT_TO_EXPIRE, handleTokenAboutToExpire);
    }
  }, [currentClient]);
  useEffect(() => {
    if (notificationsRef !== null && notificationsRef.supported()) {
      // notificationsRef.show();

    }
  }, [notificationsRef]);

  return (
    <TwilioContext.Provider
      value={{
        client: currentClient
        // conversationsList: currentConversationsList
      }}
    >
      <ReactNotifications
        onRef={(ref) => setNotificationsRef(ref)} // Required
        title='New Message' // Required
        body={message.type === 'media' ? 'new file send' : message.body}
        icon='icon.png'
        tag='New Message'
        timeout='5000'
        onClick={() => window.open(`/app/appeals-details/${message.attributes.conversations}`)}
      />

      {children}
    </TwilioContext.Provider>
  );
};
export const TwilioConsumer = TwilioContext.Consumer;

export default TwilioContext;
