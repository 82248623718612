import api from './index';
import fileDownload from 'js-file-download';

import { trackPromise } from 'react-promise-tracker';
import { ReactTrackerAreas } from '../enums/reactTrackerAreas.enum';
import { TownI } from '../types/town';

export const TownAPI = {
  getTownList: (params): Promise<any> =>  trackPromise(api.get('/towns', { params }),ReactTrackerAreas.GET_TOWNS_LIST),
  getTownsSeasonsDates: (params): Promise<any> =>
    api.get('/towns/dates', { params }),
  updateTownsSeasonsDates: (id, data): Promise<any> =>
    api.put(`/towns/dates/${id}`, data),
  downloadTownReport:(id,name):Promise<any>=>trackPromise(
  api
  .post(`/towns/download-report/${id}`, {}, {
    headers: {
      responseType: 'blob',
    },
  })
    .then((data: any) => fileDownload(data, `${name}.csv`)),ReactTrackerAreas.DOWNLOAD_TOWN_REPORT),

  updateTownProperty: (
    id:string,
    fileObj: any,
  ):Promise<TownI> => {
    return trackPromise( api.post(`/towns/update-town-property/${id}`, fileObj, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }),ReactTrackerAreas.UPDATE_TOWN_PROPERTY);
  },
  customReport:(id,data):Promise<any>=>{
    return trackPromise( api.post(`/towns/custom-report/${id}`, data),ReactTrackerAreas.CUSTOM_REPORT);
  },
  runBOR: (): Promise<any> => {
    return trackPromise( api.post(`/users/run-bor`) );
  }
};
