import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { TownFiltersI, TownI, TownsSeasonDatesI } from 'src/types/town';
import { AppThunk } from 'src/store';
import { TownAPI } from 'src/API/towns';
import { ComparableSearchParametersI } from '../types/comarable.type';

interface TownsState {
  towns: TownI[];
  townsSeasons: TownsSeasonDatesI;
  townsReportList: TownI[];
  townReportCount: number,
  townReportFilter: TownFiltersI,
  parameters: ComparableSearchParametersI,


}

const initialState: TownsState = {
  towns: [],
  townsReportList: [],
  townReportCount: 0,
  townReportFilter: {
    page: 0,
    offset: 0,
    limit: 10,
    sort: 'townNumber',
    sortDir: 'desc'
  },
  townsSeasons: {
    data: [],
    count: 0
  },
  parameters: {
    PIN: 0,
    distance: 0.6,
    bldgAgeRange: {
      min: 0,
      max: 0,
    },
    bldgSqftRange: {
      min: 0,
      max: 0,
    },
    landSqftRange: {
      min: 0,
      max: 0,
    },
  }
};

const slice = createSlice({
  name: 'towns',
  initialState,
  reducers: {
    set(state, action: PayloadAction<{ [key: string]: any }>): void {
      const { payload } = action;
      Object.keys(payload).forEach((k) => {
        state[k] = payload[k];
      });

    },
    setTowns(state, action: PayloadAction<{ townsReportList: TownI[], count: number }>): void {
      const { townsReportList, count } = action.payload;

      state.townsReportList = townsReportList;
      state.townReportCount = count;

    },
    updateTown(state, action: PayloadAction<{ newTown: TownI }>) {
      const { newTown } = action.payload;

      state.townsReportList = state.townsReportList.map(item => item._id === newTown._id ? newTown : item);
    },

    setTownReportFilter(state, action: PayloadAction<any>): void {
      const { ...townFilters } = action.payload;
      const { townReportFilter } = state;
      state.townReportFilter = { ...townReportFilter, ...townFilters };
    }
  }
});

export const { reducer } = slice;

export const getTownsSeasonsDates = (): AppThunk => async (
  dispatch
): Promise<void> => {
  const townsSeasons = await TownAPI.getTownsSeasonsDates({});
  dispatch(slice.actions.set({ townsSeasons }));
  return townsSeasons.data;
};

export const updateTownSeasonDates = (
  id: string,
  data: any
): AppThunk => async (dispatch): Promise<boolean> => {
  try {
    await TownAPI.updateTownsSeasonsDates(id, data);
    return true;
  } catch (e) {
    console.log('error in updateTownsSeasonsDates', e);
    return false;
  }
};

export const getTownReportList = (): AppThunk => async (dispatch, getState): Promise<boolean> => {
  try {
    const {
      towns: { townReportFilter }
    } = getState();
    const { data, count } = await TownAPI.getTownList({ ...townReportFilter, buildGraph: true });
    dispatch(slice.actions.setTowns({ townsReportList: data, count }));
    return true;
  } catch (e) {
    console.log('error in getTownReportList', e);
    return false;
  }
};

export const setTownReportTableFilters = (
  params: TownFiltersI
): AppThunk => async (dispatch): Promise<void> => {
  params.offset = params.page * params.limit;
  await dispatch(slice.actions.setTownReportFilter(params));
  dispatch(getTownReportList());
};


export const updateTownProperty = (id, files): AppThunk => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append('file', files[0]);
    const newTown = await TownAPI.updateTownProperty(id, formData);

    await dispatch(slice.actions.updateTown({ newTown }));
  } catch (e) {
    console.log(e);
  }
};

export const customReport = async (id, data) => {
  try {
    await TownAPI.customReport(id, data);
    alert('done we send you on email when report be Done');
  } catch (e) {
    console.log(e);
    alert(e.message);
  }
};
