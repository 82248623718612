import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { UsersAPI } from 'src/API/users';

const initialState = {
  partners: {
    data: [],
    count: 0
  },
  partnerFilter: {
    page: 0,
    offset: 0,
    limit: 10,
    sort: 'createdAt',
    sortDir: 'desc',
    search: ''

  }
};

const slice = createSlice({
  name: 'partners',
  initialState,
  reducers: {

    setPartners(state, action: PayloadAction<{ data, count }>): void {
      const { data, count } = action.payload;
      state.partners = { data, count };
    },
    addNewPartner(state, action: PayloadAction<{ partner }>) {
      const { partner } = action.payload;

      const { partners: { data, count } } = state;
      state.partners = {
        data: [partner, ...data],
        count: count + 1
      };
    },
    updatePartner(state, action: PayloadAction<{ partner }>) {
      const { partner } = action.payload;
      const index = state.partners.data.findIndex(item => item._id === partner._id);
      if(index !== -1) {
        state.partners.data[index] = partner;
      }
    },
    deletePartner(state, action: PayloadAction<{ partnerId }>) {
      const { partnerId } = action.payload;

      const { partners: { data, count } } = state;
      const newPartner = data.filter(item=>item._id!==partnerId)
      state.partners = {
        data: [...newPartner],
        count: count - 1
      };
    },


    setPartnersFilter(state, action: PayloadAction<any>): void {
      const { ...newFilter } = action.payload;
      const { partnerFilter } = state;
      state.partnerFilter = { ...partnerFilter, ...newFilter };
    }
  }
});

export const { reducer } = slice;

export const getPartnerList = (): AppThunk => async (
  dispatch,
  getState
): Promise<void> => {
  const {
    partner: { partnerFilter }
  } = getState();
  const data = await UsersAPI.getPartners(partnerFilter);
  dispatch(slice.actions.setPartners(data));
};


export const setPartnerTableFilters = (
  params
): AppThunk => async (dispatch): Promise<void> => {
  params.offset = params.page * params.limit;
  await dispatch(slice.actions.setPartnersFilter(params));
  dispatch(getPartnerList());
};

export const createPartner = (
  body
): AppThunk => async (dispatch): Promise<void> => {
  try {
    const partner = await UsersAPI.createPartner(body);
    await dispatch(slice.actions.addNewPartner({ partner }));
  }catch (e){
    alert(e.message)
  }
};

export const updatePartner = (
  body
): AppThunk => async (dispatch): Promise<void> => {
  try {
    const partner = await UsersAPI.updatePartner(body);
    await dispatch(slice.actions.updatePartner({ partner }));
  }catch (e){
    alert(e.message)
  }
};

export const deletePartner = (
  partnerId
): AppThunk => async (dispatch): Promise<void> => {
  try {
    await UsersAPI.deletePartner(partnerId);
    await dispatch(slice.actions.deletePartner({ partnerId }));
  }catch (e){
    alert(e.message)
  }
};
