import type { SearchesChartI, SearchesFiltersI, SearchesI, SearchesMetaI, TopTownSearch } from 'src/types/reports';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { ReportsAPI } from 'src/API/reports';
import moment from 'moment';

interface ReportState {
  searches: SearchesI;
  searchFilters: SearchesFiltersI;
  searchesMeta: SearchesMetaI;
  topTowns: TopTownSearch[];
  searchesChart: SearchesChartI;
  type: string;
  startDate: any;
  endDate: any;
}

const initialState: ReportState = {
  searches: {
    data: [],
    count: 0
  },
  searchFilters: {
    page: 0,
    offset: 0,
    limit: 10,
    // filter: undefined,
    inArea: false,
    inAreaNotFound: false,
    isZeroSaving: false,
    notInArea: false,
    sort: 'createdAt',
    sortDir: 'desc',
    address: undefined
  },
  type: 'inArea',
  startDate: moment().startOf('month').toISOString(),
  endDate: moment().endOf('month').toISOString(),
  searchesChart: {
    allSearches: [],
    appeals: [],
    inAreaSearches: [],
    isZeroSearches: [],
    notInAreaSearches: []
  },
  topTowns: [],
  searchesMeta: {
    appealsCount: 1,
    appealsPercent: 0,
    inAreaCount: 0,
    inAreaPercent: 0,
    notFondCount: 0,
    notFondPercent: 0,
    notInAreaCount: 0,
    notInAreaPercent: 0,
    totalSaving: 0,
    totalSearchesCount: 0,
    totalSearchesPercent: 0,
    zeroSavingCount: 0,
    zeroSavingPercent: 0
  }
};

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    set(state, action: PayloadAction<{ [key: string]: any }>): void {
      const { payload } = action;
      Object.keys(payload).forEach((k) => {
        state[k] = payload[k];
      });
    },
    setYesterday(state): void {
      state = {
        ...state,
        startDate: moment().add('-1', 'month').startOf('month'),
        endDate: moment().endOf('month')
      };
    },
    setSearchesFilter(state, action: PayloadAction<{ [key: string]: any }>) {
      const { payload } = action;
      Object.keys(payload).forEach((k) => {
        state.searchFilters[k] = payload[k];
      });
    }
  }
});

export const { reducer } = slice;

export const getSearchesList = (): AppThunk => async (
  dispatch,
  getState
): Promise<void> => {
  const {
    reports: { searchFilters, startDate, endDate }
  } = getState();
  const searches = await ReportsAPI.getSearchesList({
    ...searchFilters,
    startDate,
    endDate
  });
  dispatch(slice.actions.set({ searches }));
};

export const getSearchesMeta = (): AppThunk => async (
  dispatch,
  getState
): Promise<void> => {
  const {
    reports: { startDate, endDate }
  } = getState();
  const searchesMeta = await ReportsAPI.getSearchesMeta(startDate, endDate);
  dispatch(slice.actions.set({ searchesMeta }));
};

export const getTopTownsBySearches = (): AppThunk => async (
  dispatch,
  getState
): Promise<void> => {
  const {
    reports: { startDate, endDate, type }
  } = getState();
  const topTowns = await ReportsAPI.getTopTownsBySearches(
    startDate,
    endDate,
    type
  );
  dispatch(slice.actions.set({ topTowns }));
};

export const getSearchesChart = (): AppThunk => async (
  dispatch,
  getState
): Promise<void> => {
  const {
    reports: { startDate, endDate }
  } = getState();
  const searchesChart = await ReportsAPI.getSearchesChart(startDate, endDate);
  dispatch(slice.actions.set({ searchesChart }));
};

export const setTopTownInService = (type: string): AppThunk => async (
  dispatch
): Promise<void> => {
  await dispatch(slice.actions.set({ type }));
  dispatch(getTopTownsBySearches());
};

export const exportSearchesAsCSV = (): AppThunk => async (
  dispatch,
  getState
): Promise<void> => {
  const {
    reports: { searchFilters, startDate, endDate }
  } = getState();
  await ReportsAPI.exportSearchesAsCSV({
    ...searchFilters,
    startDate,
    endDate
  });
};

export const setSearchTableFilters = (
  params: SearchesFiltersI
): AppThunk => async (dispatch): Promise<void> => {
  params.offset = params.page * params.limit;
  await dispatch(slice.actions.setSearchesFilter(params));
  dispatch(getSearchesList());
};

export const setDateRange = (startDate, endDate): AppThunk => async (
  dispatch
): Promise<void> => {
  await dispatch(slice.actions.set({ startDate, endDate }));
  dispatch(getTopTownsBySearches());
  dispatch(getSearchesChart());
  dispatch(getSearchesMeta());
  // send the default values always when change date is applied
  dispatch(
    setSearchTableFilters({
      page: 0,
      limit: 10,
      sort: 'createdAt',
      sortDir: 'desc'
    })
  );
};
