import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { PostMarketVaule, Properties } from 'src/types/properties';
import { PropertiesAPI } from 'src/API/properties';
import moment from 'moment';

interface InitialState {
  data?: Properties;
  savingNextYear?: { saving: number; isAppealed: boolean; appealId: string };
  tokenId?: string;
}

const initialState: InitialState = {
  data: {
    currentAssessment: 0,
    lastTax: 0,
    newTax: 0,
    saleAmount: 0,
    saleDate: moment().toDate(),
    saving: 0,
    success: false
  }
};

const slice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setMarketValue(state, action: PayloadAction<{ data: Properties }>): void {
      state.data = action.payload.data;
    },
    setSavingNextYear(
      state,
      action: PayloadAction<{
        saving: number;
        isAppealed: boolean;
        appealId: string;
      }>
    ): void {
      state.savingNextYear = action.payload;
    },
    setToken(state, action: PayloadAction<{ tokenId: string }>): void {
      state.tokenId = action.payload.tokenId;
    }
  }
});

export default slice;
export const { reducer } = slice;

export const getMarketValue = (params: PostMarketVaule): AppThunk => async (
  dispatch
): Promise<void> => {
  const data = await PropertiesAPI.getMarketVaule(params);

  dispatch(slice.actions.setMarketValue({ data }));
};

export const getSavingNextYear = (PIN: number): AppThunk => async (
  dispatch
): Promise<void> => {
  const data = await PropertiesAPI.getsavingNextYear(PIN);
  dispatch(
    slice.actions.setSavingNextYear({
      appealId: data.appealId,
      isAppealed: data.isAppealed,
      saving: data.saving
    })
  );
};
