import { AppealsI, AppealI, Document } from 'src/types/appeals';
import { trackPromise } from 'react-promise-tracker';
import { ReactTrackerAreas } from 'src/enums/reactTrackerAreas.enum';

import api from './index';

export const AppealsAPI = {
  getAppealsList: (params): Promise<AppealsI> =>
    trackPromise(
      api.get('/appeals', { params }),
      ReactTrackerAreas.GET_APPEALS_LIST
    ),
  getAppealsDraftList: (params): Promise<AppealsI> =>
    trackPromise(
      api.get('/appeals/draft', { params }),
      ReactTrackerAreas.GET_APPEALS_LIST
    ),

  getAppealById: (appealId): Promise<AppealI> =>
    trackPromise(
      api.get(`/appeals/${appealId}`),
      ReactTrackerAreas.GET_APPEAL_BY_ID
    ),
  deleteAppealById: (appealId): Promise<AppealsI> =>
    trackPromise(
      api.delete(`/appeals/${appealId}`),
      ReactTrackerAreas.DELETE_APPEAL_BY_ID
    ),
  updateAppealStep: (appealId, stepId, data): Promise<AppealI> =>
    trackPromise(
      api.put(`/appeals/${appealId}/${stepId}`, data),
      ReactTrackerAreas.UPDATE_APPEAL_STEP
    ),
  generateDocument: async (
    id: string
  ): Promise<{ url: string; documentHash: string }> => {
    return await api.post(`/appeals/${id}/generate-document`);
  },
  sendSignDocumentUrl: async (
    id: string
  ): Promise<Boolean> => {
    return await api.post(`/appeals/${id}/send-sign-document-url`);
  },

  sendAddNewAppealMessage: async (
    id: string
  ): Promise<Boolean> => {
    return await api.post(`/appeals/${id}/send-add-new-appeal-message`);
  },



  addDocument: async (
    id: string,
    documentHash: string,
    type:string |undefined=undefined
  ): Promise<{ documents: Document[] }> =>
    await api.post(`/appeals/${id}/add-document`, {
      documentHash,
      ...(type&&{type})
    }),
  confirmEditMarketValue: async (
    id: string,
    newSaleDate: string,
    newSaleAmount: number
  ): Promise<{ saving: number; isUserEditValue: boolean }> => {
    return await api.post(`/appeals/${id}/edit-data-action`, {
      action: 'CONFIRM',
      newSaleDate,
      newSaleAmount
    });
  },
  editMarketValue: async (
    id: string,
    newSaleDate: string,
    newSaleAmount: number
  ) => {
    return await api.put(`/appeals/${id}/edit-data`, {
      newSaleDate: newSaleDate,
      newSaleAmount: newSaleAmount
    });
  },
  postUploadFile: async (
    fileObj: FormData,
    appealID: string
  ): Promise<{ location: string; key: string }> => {
    return await api.post(`/appeals/${appealID}/upload-hud`, fileObj, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  postUploadSignFile: async (
    fileObj: FormData,
    appealID: string
  ): Promise<{ location: string; key: string }> => {
    return await api.post(`/appeals/${appealID}/upload-sign`, fileObj, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  postAddHudUrl: async (
    hudUrl: string,
    id: string
  ): Promise<{ documents: Document[] }> => {
    return await api.post(`appeals/${id}/add-hud`, { hudUrl });
  },

  postAddCustomUrl: async (
    documentURL: string,
    name: string,
    id: string
  ): Promise<{ documents: Document[] }> => {
    return await api.post(`appeals/${id}/add-custom-document`, {
      documentURL,
      name
    });
  },

  addDocumentManual: async (
    signUrl: string,
    id: string
  ): Promise<{ documents: Document[] }> => {
    return await api.post(`appeals/${id}/add-document-manual`, { signUrl });
  },


  editAddHudUrl: async (
    hudUrl: string,
    id: string
  ): Promise<{ documents: Document[] }> => {
    return await api.put(`appeals/${id}/edit-hud`, { hudUrl });
  },
  resentAppeal: async (appealId: string): Promise<{ id }> =>
    await api.post(`appeals/resent-appeal`, { appealId }),



  addAppealResult: (appealId,body) :Promise<AppealI> =>
    trackPromise(
      api.post(`/appeals/${appealId}/result`, body),
      ReactTrackerAreas.ADD_APPEAL_RESULT
    ),
  moveToNextSeasons: (appealId,body={}) :Promise<AppealI> =>
trackPromise(
  api.post(`/appeals/${appealId}/move-to-next-seasons`, body),
  ReactTrackerAreas.MOVE_TO_NEXT_SEASONS
)


};
