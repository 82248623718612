import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { BlogFiltersI, BlogI, PostBlogData } from 'src/types/blog';

import { BlogAPI } from 'src/API/blog';

interface InitialState {
  count?: number;
  data?: BlogI[];
  blogFilters?: BlogFiltersI;
  blog?: BlogI;
  allTags?: string[];
}

const initialState: InitialState = {
  count: 0,
  data: [],
  allTags: [],
  blogFilters: {
    content: '',
    title: '',
    tags: [],
    publish: false
  },
  blog: {
    _id: '',
    title: '',
    content: '',
    cover: '',
    publish: false,
    tags: ['one']
  }
};

const slice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    setBlogs(
      state,
      action: PayloadAction<{ data?: BlogI[]; count?: number }>
    ): void {
      const { count, data } = action.payload;
      state.count = count;
      state.data = data;
    },
    getBlogBy(state, action: PayloadAction<{ blog: BlogI }>): void {
      state.blog = action.payload.blog;
    },
    initEmptyBlog(state): void {
      state.blog.description = '';
      state.blog.title = '';
      state.blog._id = '';
      state.blog.cover = '';
      state.blog.content = '';
      state.blog.publish = false;
      state.blog.tags = [];
    },
    deleteBlog(state, action: PayloadAction<{ blog: BlogI }>): void {
      state.data = state.data.filter(
        (item) => item._id !== action.payload.blog._id
      );
      state.count = state.count - 1;
    },
    publishBlog(state, action: PayloadAction<{ id: string }>): void {
      state.blog.publish = true;
    },
    saveAsDraft(state, action: PayloadAction<{ blog: BlogI }>): void {
      state.blog = action.payload.blog;
      state.count = state.count + 1;
      state.data.concat(action.payload.blog);
    },
    initAllTags: (state, action: PayloadAction<{ tags: string[] }>) => {
      state.allTags = action.payload.tags;
    },
    addTag: (state, action: PayloadAction<{ tag: string }>) => {
      state.allTags.push(action.payload.tag);
    },
    removeTag: (state, action: PayloadAction<{ tag: string }>) => {
      state.allTags = state.allTags.filter(
        (item) => item !== action.payload.tag
      );
    }
  }
});

export default slice;
export const { reducer } = slice;

export const getBlogList = (params?: BlogFiltersI): AppThunk => async (
  dispatch
): Promise<void> => {
  const { count, data } = await BlogAPI.getBlogList(params);
  dispatch(slice.actions.setBlogs({ data, count }));
};

export const getBlogListAdmin = (params?: BlogFiltersI): AppThunk => async (
  dispatch
): Promise<void> => {
  const { count, data } = await BlogAPI.getBlogListAdmin(params);
  dispatch(slice.actions.setBlogs({ data, count }));
};

export const getBlogBy = (id: string): AppThunk => async (
  dispatch
): Promise<void> => {
  const blog = await BlogAPI.getBlogById(id);
  dispatch(slice.actions.getBlogBy({ blog }));
};

export const deleteBlogBy = (blog?: BlogI): AppThunk => async (
  dispatch
): Promise<void> => {
  dispatch(slice.actions.deleteBlog({ blog }));
  await BlogAPI.deleteBlogById(blog._id);
};

export const initEmptyBlog = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.initEmptyBlog());
};

export const publishBlog = (blog: BlogI): AppThunk => async (dispatch) => {
  await BlogAPI.changeStatus(blog._id, true);
  dispatch(slice.actions.publishBlog({ id: blog._id }));
};

export const saveAsDraft = (
  data: PostBlogData,
  blog: BlogI
): AppThunk => async (dispatch) => {
  let Blog;
  if (blog._id === '') {
    Blog = await BlogAPI.postBlog(data);
  } else {
    await BlogAPI.changeStatus(blog._id, false);
    Blog = await BlogAPI.putBlog(data, blog._id);
  }
  dispatch(slice.actions.saveAsDraft({ blog: Blog }));
};

export const initTags = (): AppThunk => async (dispatch) => {
  const tags = await BlogAPI.getTags();
  dispatch(slice.actions.initAllTags({ tags }));
};

export const addTag = (tag: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addTag({ tag }));
};

export const removeTag = (tag: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeTag({ tag }));
};
