import { Properties, PostMarketVaule } from 'src/types/properties';
import { ReactTrackerAreas } from 'src/enums/reactTrackerAreas.enum';
import { trackPromise } from 'react-promise-tracker';
import api from './index';

export const PropertiesAPI = {
  getMarketVaule: async (params: PostMarketVaule): Promise<Properties> =>
    trackPromise(
      api.post(`/v2/properties/market-value`, params),
      ReactTrackerAreas.POST_MARKET_VALUE
    ),
  getsavingNextYear: async (pin): Promise<any> =>
    trackPromise(api.get(`/properties/saving/${pin}`), 'SAVING_NEXT_YEAR')
};
