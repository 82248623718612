import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { ReportAPI } from 'src/API/report';

const initialState = {
  reports: {
    data: [],
    count: 0
  },
  reportFilter: {
    page: 0,
    offset: 0,
    limit: 10,
    sort: 'createdAt',
    sortDir: 'desc',
    search: undefined

  }
};

const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setReports(state, action: PayloadAction<{ data, count }>): void {
      const { data, count } = action.payload;
      state.reports = { data, count };
    },
    addNewReport(state, action: PayloadAction<{ report }>) {
      const { report } = action.payload;

      const { reports: { data, count } } = state;
      state.reports = {
        data: [report, ...data],
        count: count + 1
      };
    },
    setReportFilter(state, action: PayloadAction<any>): void {
      const { ...newFilter } = action.payload;
      const { reportFilter } = state;
      state.reportFilter = { ...reportFilter, ...newFilter };
    }
  }
});

export const { reducer } = slice;

export const getReportList = (): AppThunk => async (
  dispatch,
  getState
): Promise<void> => {
  const {
    report: { reportFilter }
  } = getState();
  const { data, count } = await ReportAPI.getReports(reportFilter);
  dispatch(slice.actions.setReports({ data, count }));
};


export const setReportTableFilters = (
  params
): AppThunk => async (dispatch): Promise<void> => {
  params.offset = params.page * params.limit;
  await dispatch(slice.actions.setReportFilter(params));
  dispatch(getReportList());
};

export const createReport = (
  body,
  onSuccess
): AppThunk => async (dispatch): Promise<void> => {
  try {
    const report = await ReportAPI.createReport(body);
    onSuccess?.();
    await dispatch(slice.actions.addNewReport({ report }));
  }catch (e){
    alert(e.message)
  }
};
