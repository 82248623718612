import type { FC, ReactNode } from 'react';
import React from 'react';
import { Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { UserRoleEnum } from 'src/enums/user';

interface PartnersAuthGuardProps {
  children?: ReactNode;

}

const PartnersAuthGuard: FC<PartnersAuthGuardProps> = ({ children }) => {
  const { user: { role } } = useAuth();
  console.log('in grad partners', role);

  if (role !== UserRoleEnum.PARTNERS) {
    return <Redirect to="/404" />;
  }
  return (
    <>
      {children}
    </>
  );
};

PartnersAuthGuard.propTypes = {
  children: PropTypes.node
};

export default PartnersAuthGuard;
