export const ENABLE_REDUX_DEV_TOOLS = true;

export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:7000/';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
};
