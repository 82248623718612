import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { Notification } from 'src/types/notification';

interface NotificationsState {
  notifications: Notification[];
  notOpenCount: number;
}

const initialState: NotificationsState = {
  notifications: [],
  notOpenCount: 0
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotifications(
      state: NotificationsState,
      action: PayloadAction<{ notifications: Notification[] }>
    ) {
      const { notifications } = action.payload;
      // eslint-disable-next-line max-len
      state.notOpenCount = notifications.reduce(
        (accumulator: number, current: Notification) =>
          (current.open ? accumulator : accumulator + 1),
        0
      );
      state.notifications = notifications;
    },
    addNewNotifications(
      state: NotificationsState,
      action: PayloadAction<{ notification: Notification }>
    ) {
      const { notification } = action.payload;
      state.notOpenCount += notification.open ? 0 : 1;
      state.notifications.push(notification);
    },
    setNotificationsOpen(state: NotificationsState) {
      state.notOpenCount = 0;
    }
  }
});

export const { reducer } = slice;

export const getNotifications = (): AppThunk => async (dispatch) => {
  // TODO
  // const notifications = await NotificationsApi.getNotifications();
  const notifications = [];
  dispatch(slice.actions.getNotifications({ notifications }));
};
export const setNotificationsOpen = (): AppThunk => async (dispatch) => {
  // TODO
  // const notifications = await NotificationsApi.setNotificationsOpen();
  dispatch(slice.actions.setNotificationsOpen());
};
export default slice;
