export enum ReactTrackerAreas {
  GET_APPEALS_LIST = 'get_appeals_list',
  GET_APPEAL_BY_ID = 'get_appeal_by_id',
  DELETE_APPEAL_BY_ID = 'delete_appeal_by_id',
  GET_COMPARABLE = 'get_comparable',
  GET_PREV_MESSAGE = 'get_prev_massage',
  UPDATE_APPEAL_STEP = 'update_appeal_step',
  GET_BLOG_LIST = 'get_blog_list',
  GET_BLOG_BY_ID = 'get_blog_by_id',
  DELETE_BLOG_BY_ID = 'delete_blog_by_id',
  GET_ALL_TAGS = 'get_all_tags',
  GET_TOWNS_LIST = 'get_towns_list',
  DOWNLOAD_TOWN_REPORT = 'download_town_report',
  UPDATE_TOWN_PROPERTY = 'update_town_property',
  CUSTOM_REPORT = 'custom_report',
  UPDATE_INVOICE = 'update_invoice',
  GET_INVOICE = 'get_invoice',
  POST_INVOICE = 'post_invoice',
  CREATE_INVOICE = 'create_invoice',
  DELETE_INVOICE = 'delete_invoice',
  POST_MARKET_VALUE = 'post_market_value',
  POST_PROPERTIES = 'post_properties',
  ACTIVATE_ACCOUNT = 'activate_account',
  GET_USERS_FROM_PARTNERS = 'get_user_from_partners',
  GET_INIVITATIONS_FROM_ADMIN = 'get_invititations_from_admin',
  GET_PARTNERS_LIST = 'get_partners_list',
  CREATE_NEW_PARTNER = 'create_new_partner',
  UPDATE_PARTNER = 'update_partner',
  DELETE_PARTNER = 'delete_partner',
  GET_REFERRALS_LIST = 'get_referrals_list',
  CREATE_NEW_REFERRAL = 'create_new_referral',
  DELETE_REFERRAL = 'delete_referral',
  UPDATE_REFERRAL_STATUS = 'update_referral_status',
  ADD_APPEAL_RESULT = 'add_appeal_result',
  MOVE_TO_NEXT_SEASONS = "move_to_next_seasons",
  GET_REPORTS_LIST = 'get_reports_list',
  CREATE_NEW_REPORT = 'create_new_report',
}
