import { BlogFiltersI, BlogI } from 'src/types/blog';
import { trackPromise } from 'react-promise-tracker';
import { ReactTrackerAreas } from 'src/enums/reactTrackerAreas.enum';

import api from './index';

interface BlogData {
  cover?: string;
  content?: string;
  tags?: string[];
  title?: string;
  description?: string;
}

export const BlogAPI = {
  getBlogList: (
    params?: BlogFiltersI
  ): Promise<{ data: BlogI[]; count: number }> =>
    trackPromise(api.get('/blog', { params }), ReactTrackerAreas.GET_BLOG_LIST),
  getBlogListAdmin: (
    params?: BlogFiltersI
  ): Promise<{ data: BlogI[]; count: number }> =>
    trackPromise(
      api.get('/blog/filter', { params }),
      ReactTrackerAreas.GET_BLOG_LIST
    ),
  getBlogById: (blogId: string): Promise<BlogI> =>
    trackPromise(api.get(`/blog/${blogId}`), ReactTrackerAreas.GET_BLOG_BY_ID),
  PutStatus: (blogId: string): Promise<BlogI> =>
    trackPromise(
      api.get(`/blog/change-status/${blogId}`),
      ReactTrackerAreas.GET_BLOG_BY_ID
    ),
  postUploadFile: (
    fileObj: any
  ): Promise<{ location: string; key: string }> => {
    return api.post(`/blog/upload-file`, fileObj, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  postBlog: (paramas: BlogData): Promise<BlogI> => {
    return api.post(`/blog`, paramas);
  },
  putBlog: (paramas: BlogData, Id: string): Promise<BlogI> => {
    return api.put(`/blog/${Id}`, paramas);
  },
  deleteBlogById: (appealId): Promise<any> =>
    trackPromise(
      api.delete(`/blog/${appealId}`),
      ReactTrackerAreas.DELETE_BLOG_BY_ID
    ),
  changeStatus: (id: string, status: boolean): Promise<void> => {
    return api.put(`/blog/change-status/${id}`, {
      publish: status
    });
  },
  getTags: (): Promise<string[]> =>
    trackPromise(api.get('/blog/tags'), ReactTrackerAreas.GET_ALL_TAGS)
};
