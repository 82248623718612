import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

import { AppThunk } from '../store';
import { Info, invoice, InvoiceMetaData, InvoicesFilters, statuses } from 'src/types/invoice';

import { InvoiceAPI } from 'src/API/invoice';

interface InitialState {
  data?: invoice[];
  count?: number;
  Info?: Info;
  invoicesFilters?: InvoicesFilters;
  invoiceMetaData?: Partial<InvoiceMetaData>;
}

interface PaginationParams {
  limit: number;
  page: number;
  sort: string;
  sortDir: string;
}

const initialState: InitialState = {
  data: [],
  count: 0,
  invoicesFilters: {
    limit: 10,
    offset: 0,
    startDate:undefined,
    endDate: undefined
  },
  invoiceMetaData: {
    collectionInvoicesCount: 0,
    collectionInvoicesPercent: 0,
    demandLetterSentInvoicesCount: 0,
    demandLetterSentInvoicesPercent: 0,
    draftInvoicesCount: 0,
    draftInvoicesPercent: 0,
    paidInvoicesCount: 0,
    paidInvoicesPercent: 0,
    pastDueInvoicesCount: 0,
    pastDueInvoicesPercent: 0,
    sendInvoicesCount: 0,
    sendInvoicesPercent: 0,
    totalAmount: 0,
    totalAmountPercent: 0,
    totalInvoicesCount: 0,
    totalInvoicesPercent: 0,
    totalPaidAmount: 0,
    totalPaidAmountPercent: 0
  }
};

const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    initInvoices(
      state,
      action: PayloadAction<{ filters: InvoicesFilters }>
    ): void {
      state.invoicesFilters = action.payload.filters;
    },
    setInvoices(
      state,
      action: PayloadAction<{ data: invoice[]; count: number }>
    ): void {
      const { count, data } = action.payload;
      state.count = count;
      state.data = data;
    },
    setMetaData(state, action: PayloadAction<{ data: InvoiceMetaData }>): void {
      state.invoiceMetaData = action.payload.data;
    },
    setDateRange(
      state,
      action: PayloadAction<{ startDate: string; endDate: string }>
    ): void {
      state.invoicesFilters.startDate = action.payload.startDate;
      state.invoicesFilters.endDate = action.payload.endDate;
    },
    setInoviceDateRange(
      state,
      action: PayloadAction<{ startDate: string; endDate: string }>
    ): void {
      state.invoicesFilters.startDate = action.payload.startDate;
      state.invoicesFilters.endDate = action.payload.endDate;
    },
    setStatuses(state, action: PayloadAction<{ statuses: statuses[] }>): void {
      state.invoicesFilters.statuses = action.payload.statuses;
    },
    setInvoicesFilters(state, action: PayloadAction<any>): void {
      const { ...invoicesFilters } = action.payload;
      const { invoicesFilters: mainInvoicesFilters } = state;
      state.invoicesFilters = { ...mainInvoicesFilters, ...invoicesFilters };
    },
    setAmount(state, action: PayloadAction<{ amount: number[] }>): void {
      state.invoicesFilters.value = action.payload.amount;
    },
    setPagination(
      state,
      action: PayloadAction<{ parmas: PaginationParams }>
    ): void {
      state.invoicesFilters.page = action.payload.parmas.page;
      state.invoicesFilters.limit = action.payload.parmas.limit;
      state.invoicesFilters.offset =
        action.payload.parmas.limit * action.payload.parmas.page;
      state.invoicesFilters.sort = action.payload.parmas.sort;
      state.invoicesFilters.sortDir = action.payload.parmas.sortDir;
    },
    setDaysAfterSend(state, action: PayloadAction<{ days: number[] }>): void {
      state.invoicesFilters.daysAfterSend = action.payload.days;
    },
    setUpdateInvoiceInList(
      state,
      action: PayloadAction<{ Invoice: invoice }>
    ): void {
      const foundIndex = state.data.findIndex(
        (item) => item._id === action.payload.Invoice._id
      );
      state.data[foundIndex] = action.payload.Invoice;
    }
  }
});

export default slice;
export const { reducer } = slice;

export const getInvoiceList = (): AppThunk => async (
  dispatch,
  getState
): Promise<void> => {
  const {
    invoice: { invoicesFilters }
  } = getState();
  const data = await InvoiceAPI.getInvoice(invoicesFilters);
  dispatch(slice.actions.setInvoices({ data: data.data, count: data.count }));
};

export const setDateRange = (startDate, endDate): AppThunk => async (
  dispatch
): Promise<void> => {
  dispatch(slice.actions.setDateRange({ startDate, endDate }));
  const res = await InvoiceAPI.PostInvoiceMate({ endDate, startDate });
  dispatch(slice.actions.setMetaData({ data: res }));
  await dispatch(getInvoiceList());
};

export const setStatuses = (statuses: statuses[]): AppThunk => async (
  dispatch
): Promise<void> => {
  dispatch(slice.actions.setStatuses({ statuses }));
  await dispatch(getInvoiceList());
};

export const setAmount = (amount: number[]): AppThunk => async (
  dispatch
): Promise<void> => {
  dispatch(slice.actions.setAmount({ amount }));
  await dispatch(getInvoiceList());
};

export const setPaginationTableFilters = (
  parmas: PaginationParams
): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setPagination({ parmas }));
  await dispatch(getInvoiceList());
};

export const setDaysAfterSend = (daysAfterSend: number[]): AppThunk => async (
  dispatch
): Promise<void> => {
  dispatch(slice.actions.setDaysAfterSend({ days: daysAfterSend }));
  await dispatch(getInvoiceList());
};

export const setUpdateInvoiceInList = (
  id: string,
  newStatus: statuses
): AppThunk => async (dispatch): Promise<void> => {
  const data = await InvoiceAPI.putInvoice(id, newStatus);
  dispatch(slice.actions.setUpdateInvoiceInList({ Invoice: data }));
};

export const initInvoices = (): AppThunk => async (dispatch,getState): Promise<void> => {
  const {
    invoice: {invoicesFilters:{ limit,offset, startDate, endDate} }
  } = getState();
  const res1 = await InvoiceAPI.getInvoice({ limit, offset, startDate, endDate });
  const res2 = await InvoiceAPI.PostInvoiceMate({ startDate, endDate });
  dispatch(slice.actions.setInvoices({ data: res1.data, count: res1.count }));
  dispatch(slice.actions.setMetaData({ data: res2 }));
};
