import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppealI, AppealsFiltersI, AppealsI, AppealSignEnum } from 'src/types/appeals';

import { AppThunk } from '../store';
import { AppealsAPI } from '../API/appeals';

interface AppealsState {
  appeals: AppealsI;
  appealsDraft: AppealsI;

  appealFilters: AppealsFiltersI;
  appealDraftFilters:AppealsFiltersI
  query: string;
  statusFilter: string[];
  assigneeFilter: string[];
  townshipFilter: string[];
  savingAmount: number;
}

const initialState: AppealsState = {
  appealFilters: {
    page: 0,
    offset: 0,
    limit: 10,
    sort: 'createdAt',
    sortDir: 'desc',
    search: '',
    saving: [],
    towns: [],
    // submissionStatus: 'draft',
    remainingForNextStep: undefined,
    statuses: [],
    algorithm: '',
    isUserEditValue: false,
    seasons: undefined,
    hudStatus: undefined,
    isNeedSign:undefined
  },
  appealDraftFilters:{
    page: 0,
    offset: 0,
    limit: 10,
    sort: 'createdAt',
    sortDir: 'desc',
    search: '',
    saving: [],
    towns: [],
    // submissionStatus: 'draft',
    remainingForNextStep: undefined,
    statuses: [],
    algorithm: '',
    isUserEditValue: false,
    seasons: undefined,
    hudStatus: undefined,
    isNeedSign:undefined
  },
  appealsDraft:{
    data: [],
    count: 0
  },
  appeals: {
    data: [],
    count: 0
  },
  query: '',
  statusFilter: [],
  assigneeFilter: [],
  townshipFilter: [],
  savingAmount: 0
};

const slice = createSlice({
  name: 'appeals',
  initialState,
  reducers: {
    setAppeals(
      state,
      action: PayloadAction<{ data: AppealI[]; count: number }>
    ): void {
      const { data, count } = action.payload;
      state.appeals.data = data;
      state.appeals.count = count;
    },
    setAppealsDraft(
      state,
      action: PayloadAction<{ data: AppealI[]; count: number }>
    ): void {
      const { data, count } = action.payload;
      state.appealsDraft.data = data;
      state.appealsDraft.count = count;
    },
    setQuery(state, action: PayloadAction<{ query: string }>): void {
      const { query } = action.payload;
      state.query = query;
      state.appealFilters.search = query;
    },
    setAppealsFilter(state, action: PayloadAction<any>): void {
      const { ...appealFilters } = action.payload;
      const { appealFilters: mainAppealFilters } = state;
      state.appealFilters = { ...mainAppealFilters, ...appealFilters };
    },
    setAppealsDraftTableFilters(state, action: PayloadAction<any>): void {
      const { ...appealDraftFilters} = action.payload;
      const { appealDraftFilters: mainAppealFilters } = state;
      state.appealDraftFilters = { ...mainAppealFilters, ...appealDraftFilters };
    },
    setSaving(state, action: PayloadAction<{ saving: number[] }>): void {
      const { saving } = action.payload;
      state.appealFilters.saving = saving;
    },
    setTowns(state, action: PayloadAction<{ towns: number[] }>): void {
      const { towns } = action.payload;
      state.appealFilters.towns = towns;
    },
    setStatuses(state, action: PayloadAction<{ statuses: string[] }>): void {
      const { statuses } = action.payload;
      state.appealFilters.statuses = statuses;
    },
    setRemainingForNextStep(
      state,
      action: PayloadAction<{ remainingForNextStep: number | undefined }>
    ): void {
      const { remainingForNextStep } = action.payload;
      state.appealFilters.remainingForNextStep = remainingForNextStep;
    },
    setMarketValueFilter(
      state,
      action: PayloadAction<{ marketValueFilter: string[] }>
    ) {
      const { marketValueFilter } = action.payload;
      state.appealFilters.algorithm = marketValueFilter.includes('ALL')
        ? 'market_value'
        : '';
      state.appealFilters.isUserEditValue = marketValueFilter.includes(
        'isUserEditValue'
      );
    },
    setSeasons(state, action: PayloadAction<{ seasons: number[] }>): void {
      state.appealFilters.seasons = action.payload.seasons;
    },
    setHud(state, action: PayloadAction<string[]>): void {
      state.appealFilters.hudStatus = action.payload;
    },
    setIsNeedSign(state, action: PayloadAction<AppealSignEnum  |undefined>): void {
      state.appealFilters.isNeedSign = action.payload;
    },
  }
});

export const { reducer } = slice;

export default slice;

export const getAppealsList = (): AppThunk => async (
  dispatch,
  getState
): Promise<void> => {
  const {
    appeals: { appealFilters }
  } = getState();
  const data = await AppealsAPI.getAppealsList(appealFilters);
  dispatch(slice.actions.setAppeals(data));
};

export const getAppealsDraftList = (): AppThunk => async (
  dispatch,
  getState
): Promise<void> => {
  const {
    appeals: { appealDraftFilters }
  } = getState();
  const data = await AppealsAPI.getAppealsDraftList(appealDraftFilters);
  dispatch(slice.actions.setAppealsDraft(data));
};
export const setAppealsDraftTableFilters = (
  params: AppealsFiltersI
): AppThunk => async (dispatch): Promise<void> => {
  params.offset = params.page * params.limit;
  await dispatch(slice.actions.setAppealsDraftTableFilters(params));
  dispatch(getAppealsDraftList());
}

export const setAppealsTableFilters = (
  params: AppealsFiltersI
): AppThunk => async (dispatch): Promise<void> => {
  params.offset = params.page * params.limit;
  await dispatch(slice.actions.setAppealsFilter(params));
  dispatch(getAppealsList());
};

export const setQuery = (query: string): AppThunk => async (
  dispatch
): Promise<void> => {
  if (query === ' ') return;
  await dispatch(slice.actions.setQuery({ query }));
  dispatch(getAppealsList());
};

export const setSaving = (saving: number[]): AppThunk => async (
  dispatch
): Promise<void> => {
  await dispatch(slice.actions.setSaving({ saving }));
  dispatch(getAppealsList());
};
export const setTowns = (towns: number[]): AppThunk => async (
  dispatch
): Promise<void> => {
  await dispatch(slice.actions.setTowns({ towns }));
  dispatch(getAppealsList());
};

export const setStatuses = (statuses: string[]): AppThunk => async (
  dispatch
): Promise<void> => {
  await dispatch(slice.actions.setStatuses({ statuses }));
  dispatch(getAppealsList());
};

export const setRemainingForNextStep = (
  remainingForNextStep: number | undefined
): AppThunk => async (dispatch): Promise<void> => {
  await dispatch(
    slice.actions.setRemainingForNextStep({ remainingForNextStep })
  );
  dispatch(getAppealsList());
};

export const setMarketValueFilter = (
  marketValueFilter: string[]
): AppThunk => async (dispatch): Promise<void> => {
  await dispatch(slice.actions.setMarketValueFilter({ marketValueFilter }));
  dispatch(getAppealsList());
};

export const setSeasons = (seasons: number[]): AppThunk => async (
  dispatch
): Promise<void> => {
  await dispatch(slice.actions.setSeasons({ seasons }));
  dispatch(getAppealsList());
};

export const setHud = (hud: string[]): AppThunk => async (
  dispatch
): Promise<void> => {
  await dispatch(slice.actions.setHud(hud));
  dispatch(getAppealsList());
};


export const setIsNeedSign = (isNeedSign: AppealSignEnum | undefined): AppThunk => async (
  dispatch
): Promise<void> => {
  await dispatch(slice.actions.setIsNeedSign(isNeedSign));
  dispatch(getAppealsList());
};
