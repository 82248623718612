import type { SearchesI, SearchI, TopTownSearch } from 'src/types/reports';
import fileDownload from 'js-file-download';
import api from './index';

export const ReportsAPI = {
  getSearchesMeta: (startDate, endDate): Promise<SearchI> =>
    api.post('/reports/searches/meta', { startDate, endDate }),
  getTopTownsBySearches: (startDate, endDate, type): Promise<TopTownSearch[]> =>
    api.post('/reports/searches/towns', {
      startDate,
      endDate,
      type
    }),
  getSearchesChart: (startDate, endDate) =>
    api.post('/reports/searches/chart', { startDate, endDate }),
  getSearchesList: (params): Promise<SearchesI> =>
    api.get('/searches', { params }),
  exportSearchesAsCSV: (params): Promise<unknown> =>
    api
      .post('/searches/download-report', params, {
        headers: {
          responseType: 'blob'
        }
      })
      .then((data: any) => fileDownload(data, 'searches.csv'))
};
