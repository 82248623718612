import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppealI, AppealStepI, Document, Invoice } from 'src/types/appeals';
import { AppThunk } from '../store';
import { AppealsAPI } from '../API/appeals';
import { InvoiceAPI } from 'src/API/invoice';

interface AppealDetailsState {
  appeal: AppealI | null;
  error: boolean;
  indexHUDDoc?: number;
  indexForm?: number;
  previewAsUser: boolean
}

const initialState: AppealDetailsState = {
  appeal: null,
  error: false,
  indexHUDDoc: -1,
  indexForm: -1,
  previewAsUser: false
};

const slice = createSlice({
  name: 'appealDetails',
  initialState,
  reducers: {
    setAppeal(state, action: PayloadAction<{ data: AppealI }>): void {
      const { data } = action.payload;
      state.appeal = data;
    },
    updateAppealData(state, action: PayloadAction<{ data: any }>): void {
      const { data } = action.payload;
      state.appeal = {
        ...state.appeal,
        ...data
      };

    },
    togglePreviewAsUser(state): void {
      const { previewAsUser } = state
      state.previewAsUser = !previewAsUser
    },
    setError(state, action: PayloadAction<{ data: boolean }>): void {
      const { data } = action.payload;
      state.error = data;
    },
    removeAppeal(state): void {
      state.error = false;
      state.appeal = null;
      state.previewAsUser = false
    },
    updateAppealAttributes(state, action: PayloadAction<any>) {
      const newAppealAttributesObject = action.payload;
      for (let [key, value] of Object.entries(newAppealAttributesObject)) {
        console.table({ key, value });
        state.appeal[key] = value;
      }
    },
    updateTimeline(state, action: PayloadAction<{ timeline: AppealStepI[] }>) {
      const { timeline } = action.payload;
      state.appeal.timeline = timeline;
    },
    updateMaketValue(
      state,
      action: PayloadAction<{ isUserEditValue: boolean, }>
    ) {
      // state.appeal.saving = action.payload.saving;
      state.appeal.isUserEditValue = action.payload.isUserEditValue;
      // state.appeal.isNeedSign = action.payload.isNeedSign
    },
    updateEverSign(state, action: PayloadAction<{ documents: Document[] }>) {
      state.appeal.documents = action.payload.documents;
    },
    updateInovice(state, action: PayloadAction<{ invoice: Invoice }>) {
      const index = state.appeal.invoices.findIndex(
        (item) => item._id === action.payload.invoice._id
      );
      try {
        state.appeal.invoices[index] = action.payload.invoice;
      } catch (e) {
      }
    },
    addInovice(state, action: PayloadAction<{ invoice: Invoice }>) {
      try {
        state.appeal.invoices = [...state.appeal.invoices, action.payload.invoice];
      } catch (e) {
      }
    },
    deleteInovice(state, action: PayloadAction<{ invoiceId: string }>) {
      const index = state.appeal.invoices.findIndex(
        (item) => item._id === action.payload.invoiceId
      );
      try {
        state.appeal.invoices.splice(index, 1);
      } catch (e) {
      }
    },
    putDiscountInovice(state, action: PayloadAction<{ invoice: Invoice }>) {
      const index = state.appeal.invoices.findIndex(
        (item) => item._id === action.payload.invoice._id
      );
      try {
        state.appeal.invoices[index] = action.payload.invoice;
      } catch (e) {
      }
    },
    findIndexDoc(state) {
      state.indexForm = state.appeal.documents?.findIndex(
        (item) => item.type === 'AO_&&_BOR' || item.type === 'BOR'
      );

      state.indexHUDDoc = state.appeal.documents?.findIndex(
        (item) => item.type === 'HUD'
      );
    },
    // activateAccount(state, action: PayloadAction<{ success: boolean }>) {
    //   state.appeal.user.isActivateCookCountyAccount = action.payload.success;
    // }
  }
});

export const { reducer } = slice;

export const { actions: { setAppeal } } = slice

export default slice;

export const getAppealById = (appealId): AppThunk => async (
  dispatch
): Promise<void> => {
  try {
    const data = await AppealsAPI.getAppealById(appealId);
    dispatch(slice.actions.setAppeal({ data }));
    dispatch(slice.actions.setError({ data: false }));
  } catch (e) {
    dispatch(slice.actions.setError({ data: true }));
    alert(e.message);
  }
};
export const removeAppeal = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.removeAppeal());
};

export const updateAppealStep = (appealId, stepId, data): AppThunk => async (
  dispatch
): Promise<void> => {
  try {
    const appeal = await AppealsAPI.updateAppealStep(appealId, stepId, data);
    await dispatch(
      slice.actions.updateAppealAttributes({
        invoices: appeal.invoices,
        ASSaving: appeal.ASSaving,
        ASResult: appeal.ASResult,
        timeline: appeal.timeline,
        BORResult: appeal.BORResult,
        BORSaving: appeal.BORSaving,
        appealStep: appeal.appealStep,
        documents: appeal.documents,
        algorithm: appeal.algorithm,
        isUserEditValue: appeal.isUserEditValue
      })
    );
  } catch (e) {
    alert(e.message);
    console.log(e);
  }
};

export const updateMaketValue = (
  appealId: string,
  newMaketValue: number,
  newDateMaketValue: string
): AppThunk => async (dispatch): Promise<void> => {
  const data = await AppealsAPI.confirmEditMarketValue(
    appealId,
    newDateMaketValue,
    newMaketValue
  );
  dispatch(
    slice.actions.updateMaketValue({
      // saving: data.saving,
      isUserEditValue: data.isUserEditValue,
      // isNeedSign: "NEED_SIGN_NOW"
    })
  );
};

export const updateDocs = (documents: Document[], data = {}): AppThunk => async (
  dispatch
): Promise<void> => {
  await dispatch(slice.actions.updateEverSign({ documents }));
  await dispatch(slice.actions.updateAppealData({ data }));

  await dispatch(slice.actions.findIndexDoc());
};

export const findIndexDoc = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.findIndexDoc());
};

export const createInvoice = (data): AppThunk => async (
  dispatch
): Promise<void> => {
  const res = (await InvoiceAPI.CreateInvoice(data)) as any;
  dispatch(slice.actions.addInovice({ invoice: res }));
};

export const deleteInvoice = (invoiceId): AppThunk => async (
  dispatch
): Promise<void> => {
  const res = (await InvoiceAPI.DeleteInvoice(invoiceId)) as any;
  dispatch(slice.actions.deleteInovice({ invoiceId }));
};

export const updateInovices = (id: string, status): AppThunk => async (
  dispatch
): Promise<void> => {
  const res = (await InvoiceAPI.putInvoice(id, status)) as any;
  dispatch(slice.actions.updateInovice({ invoice: res }));
};

export const addDiscountInovices = (
  invoiceId: string,
  description: string,
  type: 'amount' | 'percent',
  amount: number
): AppThunk => async (dispatch): Promise<void> => {
  const res = (await InvoiceAPI.PostAddDiscount({
    amount,
    type,
    description,
    invoiceId
  })) as any;
  await dispatch(slice.actions.putDiscountInovice({ invoice: res }));
};

export const cancelDiscountInovices = (
  invoiceId: string,
  discountId: string
): AppThunk => async (dispatch): Promise<void> => {
  const res = (await InvoiceAPI.PostCancelDiscount({
    discountId,
    invoiceId
  })) as any;
  await dispatch(slice.actions.putDiscountInovice({ invoice: res }));
};

// export const activateAccount = (): AppThunk => async (
//   dispatch
// ): Promise<void> => {
//   const { success } = await UsersAPI.activateAcounnt();
//   if (success) await dispatch(slice.actions.activateAccount({ success: true }));
//   else await dispatch(slice.actions.activateAccount({ success: false }));
// };

export const addAppealResult = (appealId, data): AppThunk => async (
  dispatch
): Promise<void> => {

  const appeal = await AppealsAPI.addAppealResult(appealId, data);
  await dispatch(
    slice.actions.updateAppealAttributes({
      invoices: appeal.invoices,
      hardResult: appeal.hardResult,
      hardSaving: appeal.hardSaving,
    })
  );
};


export const togglePreviewAsUser = (): AppThunk => async (
  dispatch
): Promise<void> => {
  await dispatch(
    slice.actions.togglePreviewAsUser()
  );
}