import { combineReducers } from '@reduxjs/toolkit';
import { reducer as ReportReducer } from 'src/slices/reports.slice';
import { reducer as AppealsReducer } from 'src/slices/appeals.slice';
import { reducer as AppealDetailsReducer } from 'src/slices/appealDetails.slice';
import { reducer as ComparableReducer } from 'src/slices/comparable.slice';
import { reducer as TownsReducer } from 'src/slices/towns.slice';
import { reducer as chatReducer } from 'src/slices/chat.slice';
import { reducer as blogReducer } from 'src/slices/blog';
import { reducer as InvoiceReducer } from 'src/slices/invoice';
import { reducer as propertiesReducer } from 'src/slices/properties.slice';
import { reducer as invitationReducer } from 'src/slices/invitenUserFromPartners.slice';
import { reducer as partnerReducer } from 'src/slices/partner.slice';
import { reducer as referralReducer } from 'src/slices/referral.slice';
import { reducer as reportReducer } from 'src/slices/report.slice';

const rootReducer = combineReducers({
  reports: ReportReducer,
  appeals: AppealsReducer,
  appealDetails: AppealDetailsReducer,
  comparable: ComparableReducer,
  towns: TownsReducer,
  chat: chatReducer,
  blog: blogReducer,
  invoice: InvoiceReducer,
  properties: propertiesReducer,
  invitation: invitationReducer,
  partner: partnerReducer,
  referral: referralReducer,
  report: reportReducer
});

export default rootReducer;
