import { trackPromise } from 'react-promise-tracker';
import { ReactTrackerAreas } from 'src/enums/reactTrackerAreas.enum';
import {
  FiltersUserPartners,
  ParntersData,
  userFromPartners,
  PartnersInvatieUsersPramas
} from 'src/types/invitenUserFromPartners';
import api from './index';

export const partnersAPI = {
  getInvitationPartners: (
    params?: FiltersUserPartners
  ): Promise<ParntersData> =>
    trackPromise(
      api.get('/partner-invitation', { params }),
      ReactTrackerAreas.GET_USERS_FROM_PARTNERS
    ),
  postUploadFile: (fileObj: any): Promise<{ location: string; key: string }> =>
    api.post(`/partner-invitation/upload-file`, fileObj, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }),
  addUserSingleUser: (
    params: PartnersInvatieUsersPramas
  ): Promise<userFromPartners> => api.post(`/partner-invitation`, params),
  searchWithSavings: (params: {
    PIN?: number;
    address?: number;
    salePrice?: number;
    saleDate?: string;
  }): Promise<{
    saving: number;
  }> => api.post(`/v2/properties/search-with-savings`, params),
  editSingleUser: ({ id, ...params }): Promise<userFromPartners> =>
    api.put(`/partner-invitation/${id}`, params),
  deleteSingleUser: (id): Promise<userFromPartners> =>
    api.delete(`/partner-invitation/${id}`),
  sendInvitation: (params): Promise<userFromPartners[]> =>
    api.post(`/partner-invitation/send`, params),
  checkAddress: (params): Promise<any> =>
    api.post(`/partner-invitation/check-address`, params)
};
