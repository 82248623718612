import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';

interface ChatState {
  conversation: any,
  messages: any
}

const initialState: ChatState = {
  conversation: null,
  messages: []
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setConversation(state: ChatState, action: PayloadAction<{ conversation: any }>) {
      const { conversation } = action.payload;
      state.conversation = conversation;
    },
    setMessages(state: ChatState, action: PayloadAction<{ newMessages: any }>) {
      const { newMessages } = action.payload;
      state.messages = [...state.messages, ...newMessages];
    }
  }
});

export const { reducer } = slice;

export const addConversation = (conversation): AppThunk => (dispatch) => {
  dispatch(slice.actions.setConversation({ conversation }));
};

export const addMessages = (newMessages): AppThunk => (dispatch) => {
  dispatch(slice.actions.setMessages({ newMessages }));
};

export default slice;
