export enum TwilioConversationsEventTypeEnum {
  CONNECTION_STATE_CHANGE = 'connectionStateChanged',
  CONVERSATION_JOINED = 'conversationJoined',
  CONVERSATION_LEFT = 'conversationLeft',
  MESSAGE_ADDED = 'messageAdded',
  TOKEN_ABOUT_TO_EXPIRE = 'tokenAboutToExpire'
}

export enum TwilioConnectionStateEnum {
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  DISCONNECTING = 'disconnecting',
  DISCONNECTED = 'disconnected',
  DENIED = 'denied'
}
