import type { FC } from 'react';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import type { Theme } from 'src/theme';
import clsx from 'clsx';

interface LoaderProps {
  position?: 'absolute' | 'fixed';
  className?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    top: 0,
    width: '100%',
    zIndex: 2000
  }
}));

const Loader: FC<LoaderProps> = ({ className, position = 'fixed' }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} style={{ position }}>
      <div className={clsx('lds-ellipsis')}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Loader;
