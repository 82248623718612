import type { FC, ReactNode } from 'react';
import React, { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  makeStyles
} from '@material-ui/core';

import {
  Clipboard as ClipboardIcon,
  DollarSign as Dollar,
  FileText as FileTextIcon,
  Layout as LayoutIcon,
  LogOut as LogOutIcon,
  PenTool as Pen,
  PlusCircle,
  Users as UsersIcon,
  Send,
  Share2,
  File,
  Settings
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import useAuth from 'src/hooks/useAuth';
import { UserRoleEnum } from 'src/enums/user';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sectionsAdmin: Section[] = [
  {
    subheader: '',
    items: [
      {
        title: 'Overview',
        icon: LayoutIcon,
        href: '/app/overview'
      },

      {
        title: 'Appeal review',
        icon: ClipboardIcon,
        href: '/app/appeals'
      },
      {
        title: 'Appeal Draft',
        icon: ClipboardIcon,
        href: '/app/appeals-draft'
      },
      {
        title: 'Appeal review',
        icon: PlusCircle,
        href: '/app/manual-appeals'
      },
      {
        title: 'Towns',
        icon: ClipboardIcon,
        items: [
          {
            title: 'Appeals seasons',
            href: '/app/towns/appeals-seasons'
          },
          {
            title: 'Report',
            href: '/app/towns/report'
          }
        ]
      },
      {
        title: 'Blog',
        icon: Pen,
        href: '/app/blog'
      },
      {
        title: 'invoices',
        icon: Dollar,
        href: '/app/invoices'
      },
      {
        title: 'Partners',
        icon: UsersIcon,
        href: '/app/partner'
      },
      {
        title: 'Referral',
        icon: Share2,
        href: '/app/referral'
      },
      {
        title: 'Report',
        icon: File,
        href: '/app/report'
      },
      {
        title: 'Mailing',
        icon: Send,
        href: '/app/mailing'
      },

      // TODO
      {
        title: 'Settings',
        icon: Settings,
        href: '/app/update-properties'
      }
      // {
      //   title: 'Team tasks',
      //   icon: TrelloIcon,
      //   href: '/app/admin/team-tasks'
      // }
    ]
  }
];

const sectionsHomeOwner: Section[] = [
  {
    subheader: '',
    items: [
      {
        title: 'My Appeals',
        icon: FileTextIcon,
        href: '/app/my-appeals'
      }
      // {
      //   title: 'Settings',
      //   icon: SettingsIcon,
      //   href: '/app/settings'
      // },
      // {
      //   title: 'Advisory',
      //   icon: CompassIcon,
      //   href: '/app/advisory'
      // }
    ]
  }
];

const sectionsPartners: Section[] = [
  {
    subheader: '',
    items: [
      {
        title: 'Home',
        icon: UsersIcon,
        href: '/app/invitation-users'
      }
      // {
      //   title: 'Appeal review',
      //   icon: ClipboardIcon,
      //   href: '/app/partner-appeals'
      // }
    ]
  }
];

const getSections = (user): Section[] => {
  console.log(user.role, 'the roles');
  switch (user.role) {
    case UserRoleEnum.HOMEOWNER:
      return sectionsHomeOwner;
    case UserRoleEnum.ADMIN:
      return sectionsAdmin;
    case UserRoleEnum.SUPER_ADMIN:
      return sectionsAdmin;
    case UserRoleEnum.PARTNERS:
      return sectionsPartners;
    default:
      return [] as Section[];
  }
};

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) => {
  const key = item.title + depth;
  // FIND
  // we dont used know because we dont have subheader
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
};

const renderNavItems = ({
  items,
  pathname,
  depth = 0
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) => {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },

  navBarList: {
    paddingLeft: theme.spacing(2)
  },

  desktopDrawer: {
    width: 256,
    height: '100%'
  },
  logOutButton: {
    color: theme.palette.text.secondary,
    padding: '10px 16px',
    marginBottom: 20,
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const { user, logout } = useAuth();
  const sections = getSections(user);
  const location = useLocation();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box display="flex" justifyContent="center">
        <Logo />
      </Box>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          className={classes.navBarList}
        >
          {sections.map((section) => (
            <List key={section.subheader}>
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
          <Button
            className={classes.logOutButton}
            onClick={logout}
            startIcon={<LogOutIcon />}
          >
            <span>Log Out</span>
          </Button>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
