import type { FC } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Box, Hidden, IconButton, makeStyles, SvgIcon, Toolbar } from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import { THEMES } from 'src/constants';
import type { Theme } from 'src/theme';
import Account from './Account';
import Notifications from './Notifications';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.background.dark
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    borderBottom: '1px solid #eee',
    minHeight: 64
  }
}));

const TopBar: FC<TopBarProps> = ({
                                   className,
                                   onMobileNavOpen,
                                   ...rest
                                 }) => {
  const classes = useStyles();

  return (
    <AppBar
      position='static'
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color='primary'
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize='small' color='primary'>
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Box
          ml={2}
          flexGrow={1}
        />
        <Notifications />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {
  }
};

export default TopBar;
