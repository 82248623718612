import type { User } from 'src/types/user';
import { trackPromise } from 'react-promise-tracker';
import { ReactTrackerAreas } from 'src/enums/reactTrackerAreas.enum';
import api from './index';

export const ReferralsAPI = {
  getReferrals: (params):Promise<{data:any,count:number}> =>
    trackPromise(
      api.get('/referral-code', { params: { ...params } }),
      ReactTrackerAreas.GET_REFERRALS_LIST
    ),

  createReferral: (body) =>
    trackPromise(
      api.post('/referral-code', body),
      ReactTrackerAreas.CREATE_NEW_REFERRAL
    ),

  deleteReferral: (referralId) =>
    trackPromise(
      api.delete(`referral-code/${referralId}`),
      ReactTrackerAreas.DELETE_REFERRAL
    ),

  updateReferralStatus: (referralId, isActivated) =>
    trackPromise(
      api.put(`referral-code/update-active-status/${referralId}`, { isActivated }),
      ReactTrackerAreas.UPDATE_REFERRAL_STATUS
    )
};
