import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ComarableI,
  ComparableSearchParametersI
} from 'src/types/comarable.type';
import { AppThunk } from 'src/store';
import { ComparableAPI } from 'src/API/comparable';

interface ComparableState {
  bldgValue: string;
  buildingSqFt: string;
  comps: {
    select: boolean;
    attributes: {
      PARCELTYPE: string | null;
      Address: string;
      City: string;
      Town: string;
      NBHD: string;
      TotalValue: string;
      BldgValue: string;
      BLDGClass: string;
      BldgSqft: number;
      LandValue: string;
      LandSqft: number;
      BldgConst: string;
      BldgAge: number;
      MLT_IND: string | null;
      Per_Ass: string | null;
      Pin10: string;
      PIN14: string;
      SV_URL: string;
      TAXYR: number;
      value_description: string;
    },
    geometry: {
      rings: number[][][];
    };
    differenceInTaxes: number;
    distance: number;
    lat: number;
    lng: number;
  }[];
  compsCount: number;
  internalMaxSavings: number;
  landValue: string;
  saving: number;
  totalValue: string;
  parameters: ComparableSearchParametersI;
  algorithm: string;
}

const initialState: ComparableState = {
  bldgValue: "",
  buildingSqFt: "",
  comps: [],
  compsCount: 0,
  internalMaxSavings: 0,
  landValue: "",
  saving: 0,
  totalValue: "",
  algorithm: "",
  parameters: {
    PIN: 0,
    distance: 0.5,
    bldgAgeRange: {
      min: 0,
      max: 0
    },
    bldgSqftRange: {
      min: 0,
      max: 0
    },
    landSqftRange: {
      min: 0,
      max: 0
    },
  },
};

const slice = createSlice({
  name: 'comparable',
  initialState,
  reducers: {
    setInitialData(state, action: PayloadAction<{ age: number; bldgSqft: number; landSqft: number; }>) {
      const { age, bldgSqft, landSqft } = action.payload;
      state.parameters.bldgAgeRange = {
        min: Math.max(age - 15, 0),
        max: age + 15
      };
      state.parameters.bldgSqftRange = {
        min: bldgSqft - (bldgSqft * 0.1),
        max: bldgSqft + (bldgSqft * 0.1)
      };
      state.parameters.landSqftRange = {
        min: landSqft - (landSqft * 0.1),
        max: landSqft + (landSqft * 0.1)
      };
    },
    setComparable(
      state: ComparableState,
      action: PayloadAction<{
        newParameters: ComparableSearchParametersI;
        data;
      }>
    ) {
      const { data, newParameters } = action.payload;
      state.comps = data.comps;
      state.bldgValue = data.bldgValue;
      state.buildingSqFt = data.buildingSqFt;
      state.comps = data.comps;
      state.compsCount = data.compsCount;
      state.internalMaxSavings = data.internalMaxSavings;
      state.landValue = data.landValue;
      state.saving = data.saving;
      state.totalValue = data.totalValue;
      // state.parameters = newParameters;
    },
    setSaving(state: ComparableState, action: PayloadAction<{ saving }>) {
      const { saving } = action.payload;
      state.saving = saving;
    },
    setAlgorithm(state, action: PayloadAction<{ algorithm: string }>) {
      state.algorithm = action.payload.algorithm;
    }
  }
});

export const { reducer } = slice;
export const { setInitialData } = slice.actions;
export const getComparableList = (newParameters): AppThunk => async (
  dispatch
): Promise<void> => {
  const data = await ComparableAPI.getComparable({ ...newParameters });
  dispatch(slice.actions.setComparable({ data, newParameters }));
};

export const ClearComparable = (): AppThunk => async (
  dispatch,
  getState
): Promise<void> => {
  const {
    comparable: { parameters }
  } = getState();

  const data = {
    comps: [],
    current: null
  };
  dispatch(
    slice.actions.setComparable({ data: data, newParameters: parameters })
  );
};

export const setSaving = (saving): AppThunk => async (
  dispatch
): Promise<void> => {
  dispatch(slice.actions.setSaving({ saving }));
};

export const setAlgorithm = (algorithm: string): AppThunk => (
  dispatch
): void => {
  dispatch(slice.actions.setAlgorithm({ algorithm }));
};
