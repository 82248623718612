import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type {
  FiltersUserPartners,
  userFromPartners,
  PartnersInvatieUsersPramas
} from 'src/types/invitenUserFromPartners';
import { AppThunk } from '../store';
import { partnersAPI } from 'src/API/invitenUserFromPartners';

interface UsersFromPartnersDetailsState {
  data: userFromPartners[];
  count: number;
  userFromPatnersFilters?: FiltersUserPartners;
}

const initialState: UsersFromPartnersDetailsState = {
  data: [],
  count: 0,
  userFromPatnersFilters: {
    limit: 10,
    offset: 0,
    page: 0
  }
};

const slice = createSlice({
  name: 'Partners',
  initialState,
  reducers: {
    setUsersFromPartners(
      state,
      action: PayloadAction<{ data: userFromPartners[]; count: number }>
    ): void {
      state.count = action.payload.count;
      state.data = action.payload.data;
    },
    setUserSingleFromPanters(
      state,
      action: PayloadAction<{ data: userFromPartners }>
    ): void {
      state.count = state.count + 1;
      console.log(action.payload.data, 'reduxxxx action.payload.data');
      state.data.push(action.payload.data);
    },
    setFilters(
      state,
      action: PayloadAction<{ filters: FiltersUserPartners }>
    ): void {
      const { userFromPatnersFilters } = state;
      state.userFromPatnersFilters = {
        ...userFromPatnersFilters,
        ...action.payload.filters
      };
    }
  }
});

export const { reducer, actions } = slice;
export const { setFilters } = actions;

export default slice;

export const getUsersFromPartnersList = (): AppThunk => async (
  dispatch,
  getState
): Promise<void> => {
  const {
    invitation: { userFromPatnersFilters }
  } = getState();
  const { count, data } = await partnersAPI.getInvitationPartners(
    userFromPatnersFilters
  );
  dispatch(slice.actions.setUsersFromPartners({ count, data }));
};

export const inviteUser = (
  params: PartnersInvatieUsersPramas
): AppThunk => async (dispatch): Promise<void> => {
  const data = await partnersAPI.addUserSingleUser(params);
  dispatch(slice.actions.setUserSingleFromPanters({ data }));
};

export const applyFilters = (
  myFilters: FiltersUserPartners
): AppThunk => async (dispatch, getState): Promise<void> => {
  const {
    invitation: { userFromPatnersFilters }
  } = getState();
  const filters = { ...userFromPatnersFilters, ...myFilters };
  await dispatch(slice.actions.setFilters({ filters: filters }));
  dispatch(getUsersFromPartnersList());
};
