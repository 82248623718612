import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { ReferralsAPI } from 'src/API/referrals';

const initialState = {
  referrals: {
    data: [],
    count: 0
  },
  referralFilter: {
    page: 0,
    offset: 0,
    limit: 10,
    sort: 'createdAt',
    sortDir: 'desc',
    search: undefined

  }
};

const slice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {

    setReferrals(state, action: PayloadAction<{ data, count }>): void {
      const { data, count } = action.payload;
      state.referrals = { data, count };
    },
    addNewReferral(state, action: PayloadAction<{ referral }>) {
      const { referral } = action.payload;

      const { referrals: { data, count } } = state;
      state.referrals = {
        data: [referral, ...data],
        count: count + 1
      };
    },
    deleteReferral(state, action: PayloadAction<{ referralId }>) {
      const { referralId } = action.payload;

      const { referrals: { data, count } } = state;
      const newReferral = data.filter(item=>item._id!==referralId)
      state.referrals = {
        data: [...newReferral],
        count: count - 1
      };
    },

    updateReferralStatus(state, action: PayloadAction<{ referralId, isActivated }>) {
      const { referralId, isActivated } = action.payload;

      const { referrals: { data } } = state;
      const index = data.findIndex(item => item._id === referralId);
      if(index !== -1) {
        state.referrals.data[index].isActivated = isActivated;
      }
    },


    setReferralFilter(state, action: PayloadAction<any>): void {
      const { ...newFilter } = action.payload;
      const { referralFilter } = state;
      state.referralFilter = { ...referralFilter, ...newFilter };
    }
  }
});

export const { reducer } = slice;

export const getReferralList = (): AppThunk => async (
  dispatch,
  getState
): Promise<void> => {
  const {
    referral: { referralFilter }
  } = getState();
  const { data, count } = await ReferralsAPI.getReferrals(referralFilter);
  dispatch(slice.actions.setReferrals({ data, count }));
};


export const setReferralTableFilters = (
  params
): AppThunk => async (dispatch): Promise<void> => {
  params.offset = params.page * params.limit;
  await dispatch(slice.actions.setReferralFilter(params));
  dispatch(getReferralList());
};

export const createReferral = (
  body
): AppThunk => async (dispatch): Promise<void> => {
  try {
    const referral = await ReferralsAPI.createReferral(body);
    await dispatch(slice.actions.addNewReferral({ referral }));
  }catch (e){
    alert(e.message)
  }
};
export const deleteReferral = (
  referralId
): AppThunk => async (dispatch): Promise<void> => {
  try {
    await ReferralsAPI.deleteReferral(referralId);
    await dispatch(slice.actions.deleteReferral({ referralId }));
  }catch (e){
    alert(e.message)
  }
};

export const updateReferralStatus = (
  referralId,
  isActivated
): AppThunk => async (dispatch): Promise<void> => {
  try {
    await ReferralsAPI.updateReferralStatus(referralId, isActivated);
    await dispatch(slice.actions.updateReferralStatus({ referralId, isActivated }));
  }catch (e){
    alert(e.message)
  }
};
