import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { UserRoleEnum, isAdminRole } from 'src/enums/user';
import Loader from './Loader';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated && isAdminRole(user.role)) {
    return <Redirect to="/app/overview" />;
  } else if (isAuthenticated && user.role === UserRoleEnum.HOMEOWNER) {
    return <Redirect to="/app/my-appeals" />;
  } else if (isAuthenticated && user.role === UserRoleEnum.PARTNERS) {
    return <Redirect to="/app/invitation-users" />;
  } else {
    window.location = process.env.REACT_APP_LOGIN_PAGE_URL as any;
    return <Loader />;
  }
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
