import type { User } from 'src/types/user';
import { trackPromise } from 'react-promise-tracker';
import { ReactTrackerAreas } from 'src/enums/reactTrackerAreas.enum';
import api from './index';
import { UserRoleEnum } from 'src/enums/user';

export const UsersAPI = {
  getCurrentUser: (): Promise<User> => api.get('/users/me'),
  // activateAcounnt: (): Promise<{ success?: boolean; error?: any }> =>
  //   trackPromise(
  //     api.post('users/me/activate-cook-county-account/'),
  //     ReactTrackerAreas.ACTIVATE_ACCOUNT
  //   ),

  getPartners: (params):Promise<{data:any,count:number}> =>
    trackPromise(
      api.get('/users', { params: { ...params, role: UserRoleEnum.PARTNERS } }),
      ReactTrackerAreas.GET_PARTNERS_LIST
    ),

  createPartner: (body) =>
    trackPromise(
      api.post('/users/create-partner', body),
      ReactTrackerAreas.CREATE_NEW_PARTNER
    ),

  updatePartner: (body) =>
    trackPromise(
      api.post('/users/update-partner', body),
      ReactTrackerAreas.UPDATE_PARTNER
    ),

  deletePartner: (partnerId) =>
    trackPromise(
      api.delete(`users/${partnerId}`),
      ReactTrackerAreas.DELETE_PARTNER
    ),
  
  uploadLogo: (formData) =>
    api.post('https://api.cloudinary.com/v1_1/vrrdypuf/image/upload?api_key=879964142794695&upload_preset=vrrdypuf', formData)
};
